/* eslint no-unused-vars:0 */

/** Exposes all passed props as context properties.
 *	@example
 *	const Demo = () => (
 *		<Provider foo="bar">
 *			<Example />
 *		</Provider>
 *	);
 *	const Example = (props, context) => (
 *		<strong>foo = {context.foo}</strong>
 *	);
 */
export default class Provider {
	getChildContext() {
		// create a copy of props, omitting children:
		let { children, ...props } = this.props;
		return props;
	}

	render({ children }) {
		return children && children[0] || null;
	}
}
