// Intelligently "preload" (i.e. kick-off dynamic import) of chunks
// based on current URL

// Determine if we are on the homepage
const HOMEPAGE_RE = /^[^/]*\/\/[^/]+\/(?:index\.php)?(?:\?.*)?$/;
import { fetchHomeChunk } from '@cube/single-cube';
// import { fetchSCS } from '@cube/utils/lib/scs-api';

/**
 * Preload actions that are safe to take before the client config is loaded
 */
export function preConfigPreload() {
	//always preload singlecube and header
	// import('@cube/header-widget');
	// import('@cube/single-cube');
	// //if we are on the homepage, also do dcc
	if (window && HOMEPAGE_RE.test(window.location.href)) {
		fetchHomeChunk();
	}
}


/**
 * Preload actions that require client config to exectue
 * @param {*} config fully realized client config object
 */
export function postConfigPreload(config) {
	//TODO - pre-fetch DCC scs api
	// if (window && HOMEPAGE_RE.test(window.location.href)) {
	// 	fetchSCS('/dccs/id/gen4_dcc_foryou/', config.streamClient, config.startContentServiceOrigin);
	// }
}
