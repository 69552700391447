/* global PACKAGE, EXTERNAL_CSS */
import 'style';
import preact, { h, Component, render } from 'preact';
import IntlWrapper from './components/intl-wrapper';
import ContextProvider from './components/provider';
import createLazyComponent from './components/lazy-component';
import ScopeWrapper from './components/scope-wrapper';
import createTracker, { TrackingProvider } from '@cube/track';
import { RenderOnVisible, Spinner } from '@cube/blocks';
import { initPrivacyApis } from '@cube/privacy-utils';
import allComponents from './components';
import createStore from './lib/store';
import deepAssign from './lib/deep-assign';
import emitter from 'mitt';
import delve from 'dlv';
import { autoInsulate } from './lib/error-boundary';
import initializeScrollReset from './lib/scroll-reset';
import addCSSToDoc from './lib/add-css-to-doc';
import { getInfo } from './lib/build-details';
import { preConfigPreload, postConfigPreload } from './preload';

const CLIENT_CACHE = {};

/** export preact for anything that needs it (like app shell).  We are already bundling almost all of preact in tesseract, so it won't increase the size at all  */
export { preact };

/**
 * Factory function that initializes a tesseract instsance for a given client.  Returns an object with the various tesseract
 * methods.  The instance is cached per client and overrideConfig is not applied again when returning cached instances.
 *
 * Note: The only thing you can't reconfigure with the `configure` method after initialization is tracking information for Raven,
 * which cannot currently be reconfigured after initial configuration.
 *
 * @param {Object} options
 * @param {String} options.client The client to get config and tracking adapters dynamically for to merge with defaults
 * @param {Ojbect} [options.overrideConfig] Additional config (e.g. from Cube Configuration Service) to merge in with
 * 	the default and client config.
 */
export default function Tesseract({ client, overrideConfig }) {

	if (CLIENT_CACHE[client]) return Promise.resolve(CLIENT_CACHE[client]);

	// this is needed by the css chunk loader overridden in cube-component-cli
	window.tesseractClient = client;
	preConfigPreload(); // preload certain chunks based on our URL.  Needs to go after window.tesseractClient is set so proper css chunks are loaded

	//Get client specific config, merged with defaults at compile time, so we only have to load one config file
	let configPromise = import(/* webpackChunkName: "config/[request]" */ '../config/' + client + '/config.json?mergeWithDefault')
		.catch(err => undefined);

	return configPromise.then((clientConfig) => {

		/** Global shared pubsub for this client */
		const pubsub =  emitter();

		const config = {};

		/**
		 * Update via deep-assignment the current configuration for this instance with new configuration.  A `tesseractConfigChange` event is emitted to the pub/sub system on config unless
		 * `disableChangeNotification` is truthy.
		 *
		 * @param {Object} values New values to be deep-merged with existing config
		 * @param {Boolean} [disableChangeNotification] if `true`, no event will be emitted that a change has occurred.  Useful if the caller wants to control when components will be re-rendered on config change
		 * @returns {Object} the updated config object
		 */
		function configure(values, disableChangeNotification) {
			if (values) {
				deepAssign(config, values);
				!disableChangeNotification && pubsub.emit('tesseractConfigChange', config);
			}
			return config;
		}

		/**
		 * Get a deep copy of the current config.  Mutating the result will not mutate the internal config
		 * for this instance
		 */
		function getConfig() {
			return deepAssign({}, config);
		}

		//set the initial state of config by layering client and override config
		configure(clientConfig);
		configure(overrideConfig);

		//start our preloads that require config
		postConfigPreload(config);

		/** Global shared state store */
		const store = createStore(window.TESSERACT_INITIAL_STATE || {});

		 //The configuration for the top-level @cube/track system:
		const trackConfig = {
			...(config.tracking || {}),
			adapters: [].concat(delve(config, 'tracking.adapters'))
		};

		/*
		We will use a universal tracker instance here because one Tesseract instance
		can render to multiple Cube components one one page (Gen4).

		If we did not share this tracker,  Root TrackingProvider instances will be instantiated for
		each rendered group, and conflicts can emerge with their adaters: (EG:multiple click listeners).
		*/
		const track = createTracker('tesseract', trackConfig);

		// set up privacy related APIs (e.g., `window.__uspapi` and `window.__gpp`)
		initPrivacyApis();

		//insulate against render exceptions if configurd to do so
		if (delve(config, 'insulateRenderExceptions.insulate') === true) autoInsulate(config.insulateRenderExceptions);

		/** Roster of available components. */
		const components = { ...allComponents };

		const COMPONENTS_INFLIGHT = {};

		/** Returns the constructor for a named component.
		 *	@param {String} name			The name of the component to find (case-insensitive)
		 *	@param {Boolean} [load=false]	If `true`, unloaded async components will be loaded. Returns a Promise resolving to the loaded component.
		 *	@returns {Class|Function} Component
		 */
		function getComponent(name, load) {
			let top = name.split('.')[0];


			// async components are indicated by the top-level key being a function, and no entry in `config.syncComponents`:
			if (typeof allComponents[top]==='function' && !(config.syncComponents && config.syncComponents[top])) {
				if (!load) return true;

				// check for an existing in-flight request for the component, otherwise request it:
				let inFlight = COMPONENTS_INFLIGHT[top];
				if (!inFlight) {
					// Used for rendering service to pass components we need synchronous to front-end
					window.TESSERACT_ASYNC_LOADED_COMPONENTS && window.TESSERACT_ASYNC_LOADED_COMPONENTS.push(name);

					inFlight = COMPONENTS_INFLIGHT[top] = allComponents[top]().then( p => {
						delete allComponents[top];
						components[top] = p;
					});
				}
				// regardless of whether the component request was cached, always return the value for `name`
				return inFlight.then( () => getComponent(name) );
			}

			name = name.replace(/\.default$/, '');
			let component = delve(components, name);
			if (typeof component==='object' && component && typeof component.default==='function') {
				component = component.default;
			}

			return component;
		}


		/** Compositional component that exposes tesseract's context properties into a tree.
		 *	@example
		*		import { Provider } from '@cube/tesseract';
		*		const LogContext = (props, context) => {
		*			console.log(context);
		*		};
		*		render(
		*			<Provider>
		*				<LogContext />
		*			</Provider>
		*		);
		*	@public
		*/
		class Provider extends Component {

			triggerRerender = () => {
				this.setState({});
			};

			componentWillMount() {
				//trigger re-render on config changes
				pubsub.on('tesseractConfigChange', this.triggerRerender);
			}

			componentWillUnmount() {
				pubsub.off('tesseractConfigChange', this.triggerRerender);
			}

			/* NOTE: due to a bug in the confluence of the babel jsx plugin, and preact when props is spread in jsx without adding any other props,
			 * use the h() calls instead of jsx to avoid this.props from improperly being blown away
			 */
			render(props) {
				return (
					h(ContextProvider, { pubsub, store, config }, [
						h(TrackingProvider, { ...trackConfig, track }, [
							h(IntlWrapper, {}, [
								h(ScopeWrapper, { ...props })
							])
						])
					])
				);
			}
		}

		const PENDING_FALLBACK = (
			<tesseract-widget-loading class="tesseract-widget-loading">
				<Spinner />
			</tesseract-widget-loading>
		);

		const LazyComponent = createLazyComponent({
			config,
			getComponent,
			Provider,
			fallback: PENDING_FALLBACK
		});

		/** Apply scroll-reset **/
		initializeScrollReset(config);

		/** Render a named widget.
		*	If the name represents a widget within an async chunk not yet loaded,
		*	transparently loads the chunk and renders the widget once available.
		*	@param {Object} props
		*	@param {String} props.name		The name of a widget to load and render
		*	@public
		*/
		class Widget extends Component {
			shouldLazyLoad(name) {
				let per = config.lazyComponents && config.lazyComponents[name],
					overall = config.lazyLoad;
				return per!==false && per || overall;
			}
			render({ lazyLoad, withWidget, ...props }) {
				//withWidget provides the Widget function to its child so we don't have to keep importing tesseract to load widgets
				let child = <LazyComponent withWidget={withWidget && Widget} {...props} />,
					pendingFallback = PENDING_FALLBACK,
					height;
				if (lazyLoad!==false) {
					let lazy = this.shouldLazyLoad(props.name);
					lazyLoad = !!lazy || lazy===0;
					height = lazy && typeof lazy==='object' ? lazy.height : lazy;
				}
				return lazyLoad ? <RenderOnVisible>{ visible => (
					<LazyComponent withWidget={withWidget && Widget} {...props} height={height} pendingFallback={pendingFallback} disabled={!visible} />
				) }</RenderOnVisible> : child;
			}
		}

		/** Render a named component with the given props into a parent element.
		 *	Detects previously rendered child components for the given parent element and replaces them.
		*	@param {String} name		Case-insensitive component name
		*	@param {Object} [props={}]	Props to render the component with ("data to pass in")
		*	@param {Element} into		Parent element into which the component should be rendered.
		*	@param {Element} [replace]  Element to replace.  If not supplied, will replace the last instance of `name` that was rendered in the same `into`
		*	@returns {Component} component instance
		*/
		function renderComponent(name, props, into, replace) {
			if (Array.isArray(config.render_restriction) && config.render_restriction.indexOf(name) >= 0) {
				return null;
			}

			let lcName = String(name).toLowerCase(),
				rendered = into._renderedComponents || (into._renderedComponents = {}),
				component;

			props = props || {};

			let ref = c => {
				component = c;
				props.ref && props.ref(c);
			};


			//LazyComponent will use ref_ prop to add ref prop to the real rendered component
			render((
				<Widget name={name} {...props} ref_={ref} ref={null} />
			), into, replace || rendered[lcName]);

			rendered[lcName] = component;

			return component;
		}

		/*  Inject a stylesheet link into document.head if necessary
		 * EXTERNAL_CSS is a defined global replaced by webpack/cube-component-cli with "false" if sources are inlined (e.g. dev mode)
		 * 'tesseract.css' if doing a client specific build, or '%prefix%.tesseract.css' if doing a multi-client build
		 */
		addCSSToDoc(EXTERNAL_CSS, client);

		// HMR Setup (compiled out of production builds)
		/*
		if (module.hot) {
			// Track active (mounted) components, to be updated in response to HMR
			let mounted = [];

			let interop = m => m && m.default || m;

			Object.assign(LazyComponent.prototype, {
				componentDidMount() {
					mounted.push(this);
				},

				componentWillUnmount() {
					for (let i=mounted.length; i--; ) {
						if (mounted[i]===this) {
							mounted.splice(i, 1);
							break;
						}
					}
				}
			});

			let clear = obj => {
				for (let i in obj) if (Object.prototype.hasOwnProperty.call(obj, i)) {
					delete obj[i];
				}
			};

			module.hot.accept([
				'./components',
				'./config'
			], () => setTimeout(() => {
				clear(COMPONENTS_INFLIGHT);
				clear(allComponents);
				clear(components);
				clear(config);
				Object.assign(allComponents, interop(require('./components')));
				Object.assign(components, allComponents);
				Object.assign(config, interop(require('./config')));
				mounted.forEach( component => {
					component.setState({ child: null });
				});
			}));
		}
		*/

		/**
		 * exported API - some items are only exported for unit testing purposes
		 */
		return (CLIENT_CACHE[client] = {
			configure,
			getConfig,
			pubsub,
			track,
			components,
			getComponent,
			Provider,
			PENDING_FALLBACK,
			LazyComponent,
			Widget,
			renderComponent
		});
	});
}

/** Package version at build-time */
export const VERSION = PACKAGE.version;

export function INFO() {
	return getInfo();
}
