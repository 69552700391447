/* global __webpack_public_path__*/
/* eslint-disable camelcase */

let TESSJSON;

function readTesseractJson() {
	return fetch(__webpack_public_path__ + 'tesseract.json')
		.then((response) => response.json());
}

export function getInfo() {
	if (TESSJSON) return Promise.resolve(TESSJSON);

	return readTesseractJson()
		.then(json => {
			TESSJSON = json;
			return TESSJSON;
		})
		.catch(err => {
			TESSJSON = { error: 'Failed to get tesseract.json' };
			return TESSJSON;
		});
}
