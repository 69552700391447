/* globals __webpack_public_path__ */

/*
* Put the appropriate client specific CSS file in the head (or body as fallback) of the page if not inlining css
*
* EXTERNAL_CSS is a defined global replaced by webpack/cube-component-cli with "false" if sources are inlined (e.g. dev mode)
* 'tesseract.css' if doing a client specific build, or '%prefix%.tesseract.css' if doing a multi-client build
*/
export default function addCSSToDoc(externalCSS, client) {
	if (externalCSS) {
		let href = __webpack_public_path__ + externalCSS.replace('%prefix%', client); // eslint-disable-line camelcase

		//don't add the link if it is already present (such as the case of server-side-rendered app)
		if (document.querySelector(`link[rel="stylesheet"][href="${href}"]`)) return;

		let link = document.createElement('link');
		link.rel = 'stylesheet';
		link.href = href;
		(document.head || document.body).appendChild(link);
	}
}
