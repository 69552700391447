/**
 * Like Object.assign, but deeply copies source into target so that source won't be mutated
 * if target is changed.  If source has a non-object value for a key, it will overwrite whatever value target had for that key.
 * If source and target both have objects at a given key, those are recursively merged
 *
 * @param {Object} target Object that is mutated to merge in the values from source
 * @param {Object} source Object to merge values into target
 */
export default function deepAssign(target, source) {
	for (let i in source) {
		if (Object.prototype.hasOwnProperty.call(source,i)) {
			if (source[i] && typeof source[i]==='object' && Array.isArray(source[i])) {
				target[i] = source[i];
			}
			else if (target[i] && source[i] && typeof target[i]==='object' && typeof source[i]==='object') {
				deepAssign(target[i], source[i]);
			}
			else if (typeof source[i] === 'object' && !Array.isArray(source[i])){
				target[i] = {};
				deepAssign(target[i], source[i]);
			}
			else {
				target[i] = source[i];
			}
		}
	}
	return target;
}
