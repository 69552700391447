/**
 * initializeScrollReset - If config.resetScrollOnNavigate true in config,
 * then this will ensure anchor links scroll to the top of the page
 *
 * @param  {Object} config Configuration object from tesseract
 */
export default function initializeScrollReset(config) {
	if (config && config.resetScrollOnNavigate && history.pushState && !history._pushStateScrollReset) {
		history._pushStateScrollReset = true;
		let { pushState } = history;
		history.pushState = (a, b, url) => {
			pushState.call(history, a, b, url);
			if (url.indexOf('#')<0) scrollTo(0, 0);
		};
	}
}
