/* eslint camelcase:0 */
/* global tesseract, __tesseract_public_path__ */


/** Attempt to derive a base URL for assets (eg: CDN root).
 *	Searches the document for (tesseract) script tags.
 *	@returns {String} url
 */
function getBaseUrl() {
	let scripts = document.getElementsByTagName('script'),
		matched, last;
	for (let i=scripts.length; i--; ) {
		let src = scripts[i].src;
		last = last || src;
		if (src.match(/\btesseract\b/gi)) {
			matched = src;
			break;
		}
	}
	return (matched || last || '').replace(/\/?[^/]+(\?.{,1000})?$/g, '') + '/';
}

let path = (
	typeof __tesseract_public_path__!=='undefined' && __tesseract_public_path__
	||
	typeof tesseract!=='undefined' && tesseract.path
	||
	getBaseUrl()
);

if (path && path.length>1) {
	if (typeof console!=='undefined') console.log(`Tesseract path: ${path}`); // eslint-disable-line no-console
	__webpack_public_path__ = path;  // eslint-disable-line
}
