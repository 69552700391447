import { h, Component } from 'preact';
import style from './style';

/** Wraps a component in a CSS-scoping inline element
 *	This is clean, but breaks things like `.parent > .some-component`
 */
export default class ScopeWrapper extends Component {
	state = {
		isLoading: this.props.isLoading===true
	};

	componentDidUpdate(prevProps) {
		if (!this.props.isLoading && prevProps.isLoading) {
			clearTimeout(this.timer);
			this.timer = setTimeout( () => this.setState({ isLoading: false }), 100);
		}
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	render({ wrap, name, isLoading: c, ...props }, { isLoading }, { config }) {
		if (wrap===false || (config.wrap===false && wrap!==true)) {
			return props.children[0];
		}

		return (
			<div
				{...props}
				class={style.scoped}
				tesseract-global-styles
				data-tesseract-component={name}
				data-tesseract-loading={isLoading}
			/>
		);
	}
}
