import emitter from 'mitt';

export default function createStore(state={}) {
	let events = emitter();

	function fireChange() {
		events.emit('change', state);
	}

	return {
		subscribe(fn) {
			events.on('change', fn);
		},

		unsubscribe(fn) {
			events.off('change', fn);
		},

		getState() {
			return state;
		},

		setState(stateUpdates) {
			Object.assign(state, stateUpdates);
			fireChange();
		},

		action: fireChange
	};
}
