import { h, cloneElement, Component } from 'preact';

export default ({ Provider, config, getComponent, fallback }) => (

	/** @private Render a named widget */
	class LazyComponent extends Component {
		counter = 0;

		setComponent(name) {
			if (!name) return this.setState({ child: null });

			let child = getComponent(name, true);
			if (!child) {
				console.error('LazyLoad error: provided name is not a valid component');
				return this.setState({ child: null });
			}

			let id = ++this.counter;

			//always update the child, even if its a promise - we don't want it to re-render as the old child component with the new child components props
			this.setState({ child });
			child.then && child.then( child => {
				// skip if we got a new component or it's SSR:
				if (id!==this.counter || !this.base) return;
				this.setState({ child });
			});
		}

		componentWillMount() {
			let { name, disabled } = this.props;
			if (!disabled) {
				this.setComponent(name, disabled);
			}
		}

		componentWillReceiveProps({ name, disabled }) {
			if (name!==this.props.name || (!disabled && this.props.disabled)) {
				this.setComponent(name);
			}
		}

		render({ name, disabled, pendingFallback, ref_, unwrapped, height, ...props }, { child: ChildComponent }) {
			if (!name) {
				console.error('LazyLoad error: no component name was provided');
				return;
			}
			if (ChildComponent === null) return;

			let isLoading = disabled || !ChildComponent || !!ChildComponent.then,
				providerProps = {
					name,
					isLoading
				},
				child;

			pendingFallback = pendingFallback || fallback;

			if (ref_) props.ref = ref_;

			if (typeof height==='number') {
				providerProps.style = { minHeight: height + 'px' };

				pendingFallback = cloneElement(pendingFallback, {
					style: {
						minHeight: height + 'px',
						lineHeight: height + 'px'
					}
				});
			}

			child = isLoading ? pendingFallback : <ChildComponent {...props} />;

			if (!unwrapped && config.wrap!==false && Provider) {
				child = (
					<Provider {...providerProps}>
						{child}
					</Provider>
				);
			}

			return child;
		}
	}
);
