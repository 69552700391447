import { h, Component } from 'preact';
import { IntlProvider } from 'preact-i18n';
import preconf from 'preconf';
import connect from '@cube/store-connect';


// cached locale Promises
const LOCALES = {
	en: callback => callback({})
};


// attempt to load the given locale definition from a bundle loader locales.json
function loadLocale(locale, callback) {
	let lc = String(locale).toLowerCase().replace(/[^\w-]/g,''),
		lang = lc.split('-')[0],
		def = LOCALES[lc] || LOCALES[lang];
	if (def) {
		def(callback);
	}
}


let configure = preconf(null, {});


/** HOC that asynchronously fetches and populates preact-i18n's `<IntlProvider>` with a localization definition.
 *	Locale is controlled by a `locale` property in `context.store`, defaulting to `context.config.locale`.
 */
@connect( state => ({
	locale: state.locale
}))
@configure({
	defaultLocale: 'locale'
})
export default class IntlWrapper extends Component {
	state = {
		definition: {}
	};

	getLocale(props) {
		return props.locale || props.defaultLocale;
	}

	componentWillMount() {
	 const { defaultLocale, locale } = this.props;
	 if (locale && defaultLocale !== locale) {
		 loadLocale(locale, definition => {
			 this.setState({ definition });
		 });
	 }
	}

	/** check if the active locale has changed, then fetch & apply the corresponding locale data. */
	componentWillReceiveProps(nextProps) {
		let nextLocale = this.getLocale(nextProps),
			locale = this.getLocale(this.props);
		if (nextLocale!==locale) {
			loadLocale(nextLocale, definition => {
				this.setState({ definition });
			});
		}
	}

	render(props, { definition }) {
		return <IntlProvider {...props} definition={definition} />;
	}
}
